// @file
// Login styles
//
@import "base/variables";
@import "base/mixins";
@import "radix/mixins";

.pane-oa-home-default {
  .modules {
    .block {
      border-radius: 0;
      width: 80%;

      @include breakpoint(sm) {
        width: 50%;
      }

      h2 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .entry-placeholder {
      display: none;
    }
  }

  #block-user-login {
    margin-top: 20%;
    border-style: none;
    min-height: 0;
    padding-bottom: 20px;
    margin-left: 10%;
    margin-right: 10%;

    @include breakpoint(sm) {
      margin-left: 25%;
      margin-right: 25%;
    }

    h2 {
      @include loginhdr
    }
  }
}
